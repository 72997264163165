import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import theme from 'shared/styles/theme'
import Centered from '../Centered'
import DocumentLayout from '../DocumentLayout'
import Laptop from '../ui/Laptop'

const breakpointSmall = '600px'
const breakpointMedium = '750px'
const breakpointLarge = '950px'

const headerStyles = css`
  background-color: ${theme.darkDarkBlue};
  padding: 2em 0;
`

const contentStyles = css`
  margin: 3em 0;

  .feature-tour-content {
    position: relative;

    p {
      @media (min-width: ${breakpointSmall}) {
        text-align: justify;
      }
      @media (min-width: ${breakpointLarge}) {
        font-size: 1.2em;
      }
    }

    .laptop {
      display: none;
    }

    .laptop.zoom-language-mode .zoom {
      top: 10em;
      left: 22em;
    }

    .laptop.zoom-question-tags .zoom {
      top: -1em;
      left: 27em;
    }

    @media (min-width: ${breakpointMedium}) {
      min-height: 26em;

      + .feature-tour-content {
        margin-top: 5em;
      }

      .laptop {
        display: block;
        position: absolute;
        top: 0;
        font-size: 1rem;
      }

      &:nth-child(odd) {
        padding-right: 60%;
        .laptop {
          left: calc(40% - 2em);
        }
      }

      &:nth-child(even) {
        padding-left: 60%;
        .laptop {
          right: calc(40% - 2em);
        }
      }
    }
  }
`

const FeatureTour = () => (
  <DocumentLayout className="subpage-blue">
    <div css={headerStyles}>
      <Centered className="spacing">
        <p className="text-xlarge">
          While we have many features in Questionable and we're adding new ones all the time, this is the short list of
          things that will give you a good idea of our main features.
        </p>
      </Centered>
    </div>

    <Centered>
      <div css={contentStyles} className="spacing">
        <div className="feature-tour-content">
          <Laptop image="author-question.png" />
          <h2>Questions</h2>
          <p>
            Your account can have as many questions as you need. Get questions from our Professional Tests or create
            them yourself. Either way, they're your questions to edit and to re-use on any number of tests as you wish.
          </p>
          <p>
            Custom Question Settings lets you set Points Available, Difficulty, Random Option Order, and even Multiple
            Correct Answers.
          </p>
        </div>

        <div className="feature-tour-content">
          <Laptop className="zoom-language-mode" image="author-question.png" zoomImage="zoom-language-mode.png" />
          <h2>Language Mode</h2>
          <p>
            Questions can be authored with real code in them via Markdown Syntax or a specific coding language like
            JavaScript, Ruby, Python, Java, PHP and many more.
          </p>
        </div>

        <div className="feature-tour-content">
          <Laptop image="question-settings.png" />
          <h2>Question Settings</h2>
          <p>
            There are numerous questions settings that can be adjusted including the difficulty, points available, a
            mode for multiple correct answers, and more.
          </p>
        </div>

        <div className="feature-tour-content">
          <Laptop className="zoom-question-tags" image="author-question.png" zoomImage="zoom-question-tags.png" />
          <h2>Question Tags</h2>
          <p>
            Tagging questions not only makes them easier to organize and search for, we can show some unique analytics
            based on test takers getting certain questions right or wrong and aggregating which tags of questions they
            did better in.
          </p>
          <p>See Gap Analysis below for more insight.</p>
        </div>

        <div className="feature-tour-content">
          <Laptop image="tests.png" />
          <h2>Tests</h2>
          <p>
            Make any number of tests that you need. When you add questions to your test, they will show up in your
            Question Bank so that you can re-use them on any other test you make.
          </p>
        </div>
        <div className="feature-tour-content">
          <Laptop image="test-settings.png" />
          <h2>Test Settings</h2>
          <p>
            Customize the test settings to determine a custom time limit, whether the questions will be shown at random,
            whether the test is pausable/resumable, and other options.
          </p>
          <p>
            There is additional settings that can be applied to each test on a per-invite basis. See below for more
            details.
          </p>
        </div>

        <div className="feature-tour-content">
          <Laptop image="test-invite.png" />
          <h2>Invite Test Takers</h2>
          <p>
            Either create Sharable Links to pass around to test takers, or use the Groups feature to create lists of
            test takers to invite all at once.
          </p>
          <p>You can also preview the test yourself as a Test Admin.</p>
        </div>
        <div className="feature-tour-content">
          <Laptop image="test-invite-settings.png" />
          <h2>Invite Settings</h2>
          <p>
            Aside from test settings, each invite can have it's own set of settings which can differ from other invites.
            This is where you can determine if a password is required, if the user must register their name and email to
            the test, and whether or not they will be able to see their results after.
          </p>
        </div>

        <div className="feature-tour-content">
          <Laptop image="analytics.png" />
          <h2>Results and Gap Analysis</h2>
          <p>
            Each test takers results can be reviewed including a report of their test and what answers they chose for
            each question, but also you can also see their Gap Analysis report which gives you insight as to which
            topics and how well they did on a per-topic basis. This comes from the tagging feature mentioned earlier.
          </p>
        </div>

        <hr />

        <p className="text-large">
          This high-level overview of features just gives a glimpse of all the cool things going on in this App. The
          best way to see how everything works is to signup and try things out. There is no monetary obligation to
          signup and no monthly commitments.
        </p>

        <Link to="/signup" className="button outline-light large">
          Signup
        </Link>
      </div>
    </Centered>
  </DocumentLayout>
)

export default FeatureTour
